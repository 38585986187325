<template>
  <div :class="{ contain: true, mobile: isMobile }">
     <div class="references">
         <div class="referencesTitle ">{{$t('my_invitee')}}</div>
         <!-- <div class="des ">{{$t('my_inviter_tips')}}</div> -->
         <div class="avareferences d-flex flex-column mt-4 mt-lg-6 mb-2 mb-lg-2">
           <div class="avatar">
              <Avatar
            :size="70"
            :hash="RecommendedInfo ? RecommendedInfo.inviterProfilePhoto : 'QmcBGzXMUrmu151Q2K37z9mdokd2fd6b2NsPk2B9f7eLP6'"
            :grade="2"
            imgType="especially"
            :did="RecommendedInfo ?RecommendedInfo.inviterAddress : ''"
            showAvatarInfo
          />
           <div class="name" v-if="!this.RecommendedInfo">{{ $t('my_references')}}</div>
          <div class="name" v-else >{{RecommendedInfo.inviterUserName ?RecommendedInfo.inviterUserName : RecommendedInfo.inviterAddress.substr(0, 6) + "..." + RecommendedInfo.inviterAddress.substr(-6)}}</div>
           </div>
         

        </div>
        <div class="alert" v-if="!this.RecommendedInfo"> <span>*</span>{{$t('my_inviter_waring')}}</div>
        <div class="qrcode " v-if="!this.RecommendedInfo">
          <div class="d-flex flex-row">
              <v-text-field
              style="font-size:12px"
            class="txt"
            ref="amount"
            v-model="inviteCode"
            height:10px
            full-width:false
            outlined
             :placeholder="$t('invitation_code_placehold')"
          ></v-text-field>
          <button class="sub"  @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
          </div>
         
        </div>
        <div class="img_O">
           <img src="@/assets/icons/image_ooo.png" alt />
        </div>
        <div class="me"> {{ $t("invitation_title_me") }}</div>
        <div class="myInfo d-flex flex-row">
        <div  class="avaInfo">
        <v-avatar
        size="138"
     >
        <img class="img" :src="srcImg" alt="avatar" />
        </v-avatar>
         
        </div>
        <div class="right">
          <div class="name">{{myInfo.name?myInfo.name:myInfo.address | namefilter}}</div>
          <div class="invitation">{{ $t("invitation_code") }}: <span>{{myInfo.inviteCode}}</span>  
          <img
            class="copy "
            :class="{ click: isCopy }"
            src="@/assets/icons/icon_copy.png"
            @click="onCopyClick"
          /> </div>
          <!-- <div class="homeLink">{{ $t("invitation_homelink") }} <img
            class="copyUptick "
            :class="{ click: isUptickCopy }"
            src="@/assets/icons/icon_copy.png"
            @click="onCopyUptickClick"
          /></div> -->
        </div>
           

        </div>
        <div class="img_O">
           <img src="@/assets/icons/image_ooo.png" alt />
        </div>
          <div class="me">{{ $t("my_inviter") }}</div>
          <!-- <div class="invitee">{{ $t("my_invitee_tips") }}</div> -->
           <div class="cont d-flex flex-lg-row flex-wrap " v-if="followList.length>0">
      <div
        class="indexfan d-flex flex-column"
        v-for="(item, index) in followList"
        :key="index"
        v-scroll="onScroll"
      >
        <div class="ava d-flex justify-center mt-6 mt-lg-6 mb-2 mb-lg-2">
          <Avatar
            style="margin: 0 auto"
            :size="70"
            :hash="item.inviteeProfilePhoto"
            :grade="item.inviteeGrade"
			      imgType="especially"
            :did="item.inviteeAddress"
            showAvatarInfo
          />
        </div>
        <div class="name">{{item.inviteeUserName ?item.inviteeUserName:item.inviteeAddress | namefilter}}</div>
        <!-- <div class="num">{{ $t("avatarlistWork") }} {{ item.workCount }}</div> -->
       
      </div>
         </div>
           <div class="anyone" v-else>{{ $t("not_invitee") }}</div>
     </div>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import api from "@/api";
import Avatar from "@/components/avatar/index.vue";

import { getFileSrc } from "@/utils/file";
const WORK_KEY = "WORK";
export default {
  components: { Avatar},
  data: function () {
    return {
        isloadList:false,
        isShowLoading: false,
        pageNumber: 0,
        pageSize: 20,
        totalPage: 0,
        folderClick:false,
        soldClick : false,
        ownerClick:true,
        clickMode:"management",
        RecommendedInfo:{},
        myInfo:{},
        followList: [],
        inviteCode:'',
        srcImg:'',
          isCopy: false,
      isUptickCopy:false,
       
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  filters: {
    namefilter: function(value) {
    if(value && value.length > 12) {
      return value.substr(0,5)+"..."+value.substr(-5);
    } else {
      return value;
    }
    }
  },
  async mounted() {
    this.getMyinfo();
    this.myRecommended();
    // window.eventBus.$on("LangChange", this.onLangChange);
    this.getInviteList();
   
  },
 
  methods: {
    async myRecommended(){
      let param={
        did:this.$store.state.did
      }
    let res=  await api.home.myRecommended(param);
      this.RecommendedInfo= res.data
    },
   async subCommit(){
      let params={
        inviteCode:this.inviteCode
      }
     if(this.inviteCode){
          //检查校验码
    
     let result = await api.home.checkInviteCode(params)
    if(!result.success){
       this.$toast("error", result.msg)
       return;
    }
     }else{
       this.$toast("error", this.$t('invitation_code_empty'))
       return;
     }
    
  
      
      let res = await api.home.addInviteCode(params)
      if(res.success){
           this.$toast("success", this.$t('success')).then(()=>{
             this.myRecommended();
           })

      }else{
         this.$toast("error", res.msg)
      }

    },
   async getMyinfo(){
            let param = {address: this.$store.state.did  };
			  if(!this.$store.state.did){
				  return;
        }
        debugger
         let res = await api.home.getMyInfo(param);
          this.myInfo = res.data
          if(this.myInfo.profilePhoto)
          {
             this.srcImg =  await getFileSrc(WORK_KEY,this.myInfo.profilePhoto);
          }else{
             this.srcImg =  await getFileSrc(WORK_KEY,"QmcBGzXMUrmu151Q2K37z9mdokd2fd6b2NsPk2B9f7eLP6");  
          }
         
    },
    	onCopyClick() {
      debugger
			var input = document.createElement('input')
			input.value =  this.myInfo.inviteCode;
			document.body.appendChild(input);
			input.select();
			document.execCommand('copy');
			document.body.removeChild(input);
			this.isCopy = true;
      setTimeout(() => this.isCopy = false, 100);
      this.$toast("success", this.$t('copt_success'))
    },
     onCopyUptickClick(){
        var input = document.createElement('input')
        //https://marketbeta.uptick.network/showcase?did=0x79A475E22c347206Fb774A7681f3b664B1673e79
			input.value = "https://marketbeta.uptick.network/showcase?did="+this.myInfo.address;
			document.body.appendChild(input);
			input.select();
			document.execCommand('copy');
			document.body.removeChild(input);
			this.isUptickCopy = true;
      setTimeout(() => this.isUptickCopy = false, 100);
      this.$toast("success", this.$t('copt_success'))

    },
     async getInviteList() {
      
      this.pageNumber++;
      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        userAddress: this.$store.state.did,
        //userAddress:'iaa1l4799la336dlgjvpmf3avel9xel4u47k7sk45m'
      };
      this.isShowLoading = true;
      let res = await api.home.myInviteList(params);
      let list = res.data.list;
      this.followList = this.followList.concat(list);
      this.totalPage = res.data.totalPage;

      this.isShowLoading = false;
    },
     onScroll(e) {
  
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;
       console.log("23333333",scrollTop,clientHeight,scrollHeight)
      if (
        scrollTop + clientHeight >= scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        this.getInviteList();
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.contain {
    width: 100%;
    margin: 0;
    padding: 0;
  .references{
      .referencesTitle{
            margin: 68px auto 24px;
            width: 180px;
            font-family: Helvetica;
            font-size: 25px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 30px;
            letter-spacing: 0px;
            color: #270645;
            text-align: center
      }
      .des{
    
          margin: 0 auto;
          width: 660px;
          font-family: Helvetica;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645; 
          text-align: center;
          text-align: center
      }
      .avareferences{

        margin: 0 auto;
        width: 131px;
        height: 131px;
        background-image: linear-gradient(
          #ffffff, 
          #ffffff), 
        linear-gradient(
          #6f58d9, 
          #6f58d9);
        background-blend-mode: normal, 
          normal;
        box-shadow: 0px 0px 9px 0px 
          rgba(0, 0, 0, 0.09);
        border-radius: 5px;
  .avatar{
        margin: auto;
        text-align: center;
        .name{
          width: 120px;
          font-family: Helvetica;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645;
          
           overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
        }
      }
      }
      .alert{
         margin: 24px auto 22px;
         text-align: center;
        width: 500px;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #766983;
      span{
         color: #cc0000;
      }
      }
      .qrcode{
          width: 380px;
          margin: 0 auto;
        text-align: center;
         &::v-deep .v-input {
      width: 290px;
    height: 40px;
   
        
      }
       &::v-deep .v-input__slot{
         min-height: 40px !important;
       }
      .sub{
        margin-left: 20px;
        width: 86px;
        height: 41px;
        background-image: linear-gradient(
          #7800f4, 
          #7800f4), 
        linear-gradient(90deg, 
          #d300fd 0%, 
          #a439fe 26%, 
          #7471ff 52%, 
          #00fdcf 100%), 
        linear-gradient(
          #1d42ff, 
          #1d42ff);
        background-blend-mode: normal, 
          normal, 
          normal;
        border-radius: 20px;
        color: #ffffff;
      }
      }
      .img_O{
        width: fit-content;
        margin: 30px auto;
        img{
          width: 4px;
	      height: 50px;

        }
      }
      .me{
        margin: 0 auto 24px;
          font-family: Helvetica;
          font-size: 25px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645; 
          text-align: center;

      }
      .invitee{
         margin: 0 auto 24px;
         text-align: center;
        width: 610px;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #270645;
      }
      .anyone{
            margin: 0 auto 340px;
        	width: 370px;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #766983;
      text-align: center;
      }
      .cont {
    max-width: 670px;
    margin: 0 auto;
    margin-bottom: 80px;
    .indexfan {
      cursor: pointer;
      width: 130px;
      height: 130px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#6f58d9, #6f58d9);
      background-blend-mode: normal, normal;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      margin-right: 4px;
      margin-top: 4px;

      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    .ava {
      width: 100%;
    }
    .num {
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      width: 100%;
      text-align: center;
    }
    .name {
      width: 100%;
      text-align: center;
	font-family: Helvetica;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: 0px;
	color: #270645;
    }
   
  }
      .myInfo{
             margin: 0 auto 24px;
        width: 520px;
        	height: 221px;
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px 
        rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      border: solid 1px #cccccc;
      .avaInfo{
        margin: 40px 35px 50px;
        .img{
          border: solid 2px #ffffff;
          object-fit: cover;
        }
       
      }
      .right{
      margin: 65px 0;
      .name{
        width: 270px;
      font-family: Helvetica;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #270645;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
      }
      .invitation{
        margin-top: 20px;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #270645;
  span{
    font-size: 18px;
	font-weight: bold;
  }
    .copy {
      width: 13px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      cursor: pointer;
    }
    // .click {
    //   margin-top: 5px;
    // }
    
      }
      .homeLink{
        margin-top: 25px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #270645;
      }
      .copyUptick{
       width: 13px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      cursor: pointer;

    }

      }
      }
  }
   
   
   
   
   
 &.mobile{
   

    }
  
}
</style>
